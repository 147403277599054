import axios from "axios";
import { brandAxios } from "../../utility/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import DefaultConfig from "../../backendConfig.json";
import { changeMappingStatus, setBrandDetails, setRoles } from "./slice";

export const authPost = async (url, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await brandAxios.post(url, body);
      resolve(res?.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const linkedInSignUp = async (url, accessToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      // /api/v1/auth/getLinkedInAuth
      const res = await axios.get(url, {
        params: {
          code: accessToken,
        },
      });
      resolve(res?.data);
    } catch (err) {
      console.log("err", err);
      reject(err);
    }
  });
};

export const linkedInLogin = async (url, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(url, body);
      resolve(res?.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const checkMappedStatus = createAsyncThunk(
  "/checkMappedStatus",
  (payload, { dispatch, getState }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          auth: { accessToken },
        } = getState();
        const res = await axios.get(
          `${DefaultConfig.BASE_URL}/api/v1/profile`,
          {
            headers: {
              Authorization: `${accessToken}`,
            },
            params: payload,
          }
        );
        dispatch(
          changeMappingStatus({
            mappedStatus: res.data.data?.mapping_status,
            roles: res.data.data?.role,
          })
        );
        dispatch(setBrandDetails(res?.data?.data));
        // resolve(res?.data);
      } catch (err) {
        reject(err);
      }
    });
  }
);
