import axios from "axios";

import DefaultConfig from "../backendConfig.json";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";


export const brandAxios = axios.create({
  baseURL: DefaultConfig.BASE_URL,
  // baseURL:"http://192.168.0.90:8080"
});


// export const devAxios = axios.create({
//   baseURL:"http://192.168.0.152:8081/",

// })

export const authAxios = axios.create({
  baseURL: DefaultConfig.AUTH_AXIOS,
});

export const generateAxiosError = (message, config) => {
  const networkError = new Error(message);
  networkError.config = config;
  networkError.isAxiosError = true;
  return Promise.reject(networkError);
};
