// ** Redux, Thunk & Root Reducer Imports
import thunk from "redux-thunk";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { persistStore, persistReducer } from "redux-persist";
import navbar from "../reducers/navbar";
import layout from "../reducers/layout";
import auth from "../auth/slice";
import brand from "../brand/slice";
import storage from "redux-persist/lib/storage";

const authpersistConfig = {
  key: "auth",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["userStatus"],
};

const authPersitReducer = persistReducer(authpersistConfig, auth);

const rootReducer = combineReducers({
  auth: authPersitReducer,
  brand,
  navbar,
  layout,
});

// ** Create store
const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: false,
});

export const persistor = persistStore(store);

export default store;
