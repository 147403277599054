import { createSlice } from "@reduxjs/toolkit";
import { checkMappedStatus } from "./thunk";
import { toast } from "react-toastify";

const initialState = {
  uid: null,
  brand: null,
  email: null,
  otp: null,
  accessToken: null,
  mappedStatus: false,
  refreshToken: null,
  isAuthenticated: false,
  userStatus: null,
  ttl: null,
  roles: {},
  showBlurrCard: false,
  error: "",
  brandDetails:{},
  isBrandProfileSubmitted:false,
  showRetailersIntent:false
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signUpSuccess(state, { payload }) {
      state.uid = payload?.data?.data?.user_id;
      state.email = payload?.email;
    },
    signUpFailure(state, { payload }) {
      state.error = payload;
    },
    loginSuccess(state, { payload }) {
      state.uid = payload?.data?.data?.user_id;
      state.email = payload?.email;
    },
    loginFailure(state, { payload }) {
      state.error = payload;
    },
    registerSuccess(state, { payload }) {
      state.otp = payload?.verification_id;
      state.showBlurrCard = true;
    },
    registerFailure(state, { payload }) {
      state.error = payload;
    },
    otpSuccess(state, { payload }) {
      state.accessToken = payload?.data?.access_token;
      state.brand = payload?.data?.brand;
      state.isAuthenticated = true;
      state.mappedStatus = payload?.data?.status;
      state.roles = payload?.data?.role
      // localStorage.setItem(
      //   "userData",
      //   JSON.stringify({
      //     username: payload?.data?.name,
      //     role: payload?.data?.designatmappedStatusion,
      //     brand: payload?.data?.brand,
      //   })
      // );
    },
    otpFailure(state, { payload }) {
      state.error = payload;
    },
    refreshTokenAxios(state, { payload }) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.ttl = payload.ttl;
    },
    submitBrandProfile(state,{payload}){
      state.isBrandProfileSubmitted = payload;
    },
    changeMappingStatus(state, { payload }) {
      state.mappedStatus = payload.mappedStatus;
      state.roles = {
        ...state.roles,
        ...payload?.roles
      }
    },
    setRoles (state,{payload}){
      state.roles = payload.roles
    },
    logout(state, { payload }) {
      state.refreshToken = null;
      state.accessToken = null;
      state.isAuthenticated = null;
      state.ttl = null;
      state.error = payload;
      (state.mappedStatus = false),
        (state.uid = null),
        (state.brand = null),
        (state.otp = null),
        (state.email = null);
      state.userStatus = null;
      state.brandDetails = null;
      state.isBrandProfileSubmitted=false;
      localStorage.clear();
    },
    setUserStatus(state, { payload }) {
      state.userStatus = payload;
    },
    setBrandDetails(state,{payload}){
      state.brandDetails= {
        ...state.brandDetails,
        ...payload
      };
    },
    setRetailersIntent(state,{payload}){
      state.showRetailersIntent = payload
    },
    extraReducers: (builder) => {
      builder.addCase(checkMappedStatus.pending, (state, action) => {});
      builder.addCase(checkMappedStatus.fulfilled, (state, { payload }) => {
        state.mappedStatus = payload?.data?.mapping_status
        state.brandDetails = payload?.data;
        state.roles = payload?.data?.role;
      });
      builder.addCase(checkMappedStatus.rejected, (state, { payload }) => {});
    },
  },
});

export const {
  signUpSuccess,
  signUpFailure,
  loginSuccess,
  loginFailure,
  registerSuccess,
  registerFailure,
  otpSuccess,
  otpFailure,
  refreshTokenAxios,
  logout,
  setUserStatus,
  changeMappingStatus,
  setBrandDetails,
  setRoles,
  submitBrandProfile,
  setRetailersIntent
} = authSlice.actions;

export default authSlice.reducer;
