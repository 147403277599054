import { createSlice } from "@reduxjs/toolkit";
import {
  getTopProductList,
  getSpecificProductDetails,
  getRecentProductList,
  getRecentGlobalProductList, getAllSurveys, getMySurveys,
} from "./thunk";

const initialState = {
  recentPosts: [],
  recentGlobalPosts: [],
  recentGlobalLoading: false,
  recentPostsLoading: false,
  posts: [],
  postsLoading: [],
  allSurveys: [],
  mySurveys: [],
  allSurveysLoading: false,
  mySurveysLoading: false,
  sharedPostLoading: false,
  sharedPost: null,
  lastItemRecent: null,
  lastItem: null,
  error: "",
};

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSurveys.pending, (state, action) => {
      state.allSurveysLoading = true;
    });
    builder.addCase(getAllSurveys.fulfilled, (state, { payload }) => {
      if (!payload.last_item) {
        state.allSurveys = payload?.data;
      } else {
        state.allSurveys = [...state.allSurveys, ...payload?.data];
        state.lastItem = payload?.last_item;
      }

      state.allSurveysLoading = false;
    });
    builder.addCase(getAllSurveys.rejected, (state, { payload }) => {
      state.error = payload;
      state.allSurveysLoading = false;
    });

    builder.addCase(getMySurveys.pending, (state, action) => {
      state.mySurveysLoading = true;
    });
    builder.addCase(getMySurveys.fulfilled, (state, { payload }) => {
      if (!payload.last_item) {
        state.mySurveys = payload?.data;
      } else {
        state.mySurveys = [...state.allSurveys, ...payload?.data];
        state.lastItem = payload?.last_item;
      }

      state.mySurveysLoading = false;
    });
    builder.addCase(getMySurveys.rejected, (state, { payload }) => {
      state.error = payload;
      state.mySurveysLoading = false;
    });

    builder.addCase(getTopProductList.pending, (state, action) => {
      state.postsLoading = true;
    });
    builder.addCase(getTopProductList.fulfilled, (state, { payload }) => {
      if (!payload.last_item) {
        state.posts = payload?.data?.contents;
      } else {
        state.posts = [...state.posts, ...payload?.data?.contents];
        state.lastItem = payload?.last_item;
      }

      state.postsLoading = false;
    });
    builder.addCase(getTopProductList.rejected, (state, { payload }) => {
      state.error = payload;
      state.postsLoading = false;
    });
    builder.addCase(getRecentProductList.pending, (state, action) => {
      state.recentPostsLoading = true;
    });
    builder.addCase(getRecentProductList.fulfilled, (state, { payload }) => {
      if (!payload.last_item) {
        state.recentPosts = payload?.data?.contents;
      } else {
        state.recentPosts = [...state.recentPosts, ...payload?.data?.contents];
        state.lastItemRecent = payload?.last_item;
      }

      state.recentPostsLoading = false;
    });
    builder.addCase(getRecentProductList.rejected, (state, { payload }) => {
      state.error = payload;
      state.recentPostsLoading = false;
    });

    builder.addCase(getRecentGlobalProductList.pending, (state, action) => {
      state.recentGlobalLoading = true;
    });
    builder.addCase(
      getRecentGlobalProductList.fulfilled,
      (state, { payload }) => {
        if (!payload.last_item) {
          state.recentGlobalPosts = payload?.data?.contents;
        } else {
          state.recentGlobalPosts = [
            ...state.recentGlobalPosts,
            ...payload?.data?.contents,
          ];
          state.lastItemRecent = payload?.last_item;
        }

        state.recentGlobalLoading = false;
      }
    );
    builder.addCase(
      getRecentGlobalProductList.rejected,
      (state, { payload }) => {
        state.error = payload;
        state.recentGlobalLoading = false;
      }
    );
    builder.addCase(getSpecificProductDetails.pending, (state, action) => {
      state.sharedPostLoading = true;
    });
    builder.addCase(
      getSpecificProductDetails.fulfilled,
      (state, { payload }) => {
        state.sharedPost = payload;
        state.sharedPostLoading = false;
      }
    );
    builder.addCase(
      getSpecificProductDetails.rejected,
      (state, { payload }) => {
        state.error = payload;
        state.sharedPostLoading = false;
      }
    );
  },
});

export const { } = brandSlice.actions;

export default brandSlice.reducer;
