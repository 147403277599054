import { createAsyncThunk } from "@reduxjs/toolkit";
import { brandAxios } from "../../utility/axios";

export const getRecentProductList = createAsyncThunk(
  "/getRecentProductList",
  (payload, { dispatch, getState }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await brandAxios.post("/api/v1/posts/recent", payload);

        resolve({
          data: res?.data?.data,
          last_item: payload?.last_item,
        });
      } catch (err) {
        reject(err);
      }
    });
  }
);

export const getRecentGlobalProductList = createAsyncThunk(
  "/getRecentGlobalProductList",
  (payload, { dispatch, getState }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await brandAxios.post("/api/v1/posts/recent/global", payload);
        resolve({ data: res?.data?.data, last_item: payload?.last_item });
      } catch (err) {
        reject(err);
      }
    });
  }
);

export const getAllSurveys = createAsyncThunk(
  "/getAllSurveys",
  (payload, { dispatch, getState }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await brandAxios.get("/api/v1/survey/all");
        resolve({ data: res?.data?.data, last_item: payload?.last_item });
      } catch (err) {
        reject(err);
      }
    });
  }
);

export const getMySurveys = createAsyncThunk(
  "/getMySurveys",
  (payload, { dispatch, getState }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await brandAxios.get("/api/v1/survey/my");
        resolve({ data: res?.data?.data, last_item: payload?.last_item });
      } catch (err) {
        reject(err);
      }
    });
  }
);

export const getTopProductList = createAsyncThunk(
  "/getTopProductList",
  (payload, { dispatch, getState }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await brandAxios.post("/api/v1/posts/top", payload);
        resolve({ data: res?.data?.data, last_item: payload?.last_item });
      } catch (err) {
        reject(err);
      }
    });
  }
);

export const getSpecificProductDetails = createAsyncThunk(
  "/details",
  (payload, { dispatch, getState }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, type } = payload;
        let res
        if (type === 'survey') {
          res = await brandAxios.get(`/api/v1/survey/shared/${id}`, payload);
        } else {
          res = await brandAxios.get(`/api/v1/posts/${id}`, payload);
        }
        resolve(res?.data?.data);
      } catch (err) {
        reject(err);
      }
    });
  }
);
